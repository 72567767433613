import React from 'react';
import cx from 'classnames';
import Styles from '../../assets/styles/getStarted.module.css';


const GetStarted = props => {
const getStartedData = props.get_started_data
    return (
        <div className={Styles.getstartedSection}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={cx(Styles.getstartedContent, 'col-sm-12 text-center')}>
                        <h4>{getStartedData.labelText1}</h4>
                        <p>
                            {getStartedData.labelText2}
                        </p>
                        <div className={Styles.getStartedBtnMain}>
                            <a href={getStartedData.link} className={cx('btn blue-btn', Styles.btn)}>{getStartedData.button}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default GetStarted;