import React from 'react';
// import cx from 'classnames';
// import footerStyles from '../assets/styles/footerBottomContact.module.css';
import Cookies from 'universal-cookie';
import { navigate } from "gatsby"

export default class StickyBarFooter extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, fromPage, toPage='') {
        // event.preventDefault()
        if (typeof window !== 'undefined') {

            let cookies = new Cookies();

            let path = document.location.pathname;

            if (path.match(/hashching-home-loan-deals/g)) {
                cookies.remove('broker', { path: '/' })
            }

            if (fromPage == 'deal-list') {
               
            }

            if (fromPage == 'choose-deal') {
                
            }

            if (fromPage == 'broker-finder') {
                cookies.remove('deal', { path: '/' })
                cookies.remove('broker', { path: '/' })
            }

            if (fromPage == 'home') {
                cookies.remove('deal', { path: '/' })
                cookies.remove('broker', { path: '/' })
            }

        }
        
        return false;
    }

    handleBackClick(event, fromPage) {
        // event.preventDefault()
        if (typeof window !== 'undefined') {

            let cookies = new Cookies();

            let path = document.location.pathname;

            if (path.match(/hashching-home-loan-deals/g)) {
                cookies.remove('broker', { path: '/' })
            }

            if (fromPage == 'deal-list') {
                cookies.set('flow', 'get-started', { path: '/' })
            }

            if (fromPage == 'choose-deal') {
                cookies.set('flow', 'broker', { path: '/' })
            }

            if (fromPage == 'broker-finder') {
                cookies.remove('deal', { path: '/' })
                cookies.set('flow', 'deals', { path: '/' })
            }
            if (fromPage == 'choose-deal') {
                navigate('/hashching-home-loan-deals')
            }
        }

        window.history.back();

        return false;
    }

    render() {
        console.log(this.props)
        return (
            <section className={'bottom-sticky-buttons-section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-sm-12 bottom-sticky-buttons'}>
                            {this.props.inPage == 'home' && <div className={'headrbottom-sticky-buttons'}>
                                <div className={'row'}>
                                    <div className={'col-sm-4 sticky-buttons btn-left'}>
                                    </div>
                                    <div className={'col-sm-8 sticky-buttons btn-right'}>
                                        <a className={'btn btn-right-link active'} href="/mortgage-broker-finder" onClick={
                                            (event) => {
                                                this.handleClick(event, this.props.inPage, 'mortgage-broker-finder');
                                            }
                                        }>
                                            <span className={'btn-txt'} >Get a better broker</span>
                                        </a>
                                        <a className={'btn btn-right-link active'} href="hashching-home-loan-deals" onClick={
                                            (event) => {
                                                this.handleClick(event, this.props.inPage,  '/hashching-home-loan-deals');
                                            }
                                        }>
                                            <span className={'btn-txt'} >Get a better loan</span>
                                        </a>
                                    </div>
                                </div>
                            </div>}
                            {this.props.layout == 'middle' && <div className={'sticky-getstartd'}>
                                <div className={'d-flex justify-content-center'}>
                                    <p className={'descrption'}>Fill our 2 min. questionnaire so we can help you in your home loan
                                      
                                </p>
                                    <div className={'sticky-buttons btn-right'}>
                                        {this.props.inPage == 'deal-list' &&  <a className={'btn btn-right-link active'} href="/loan-enquiry" onClick={
                                            (event) => {
                                                this.handleClick(event, this.props.inPage);
                                            }
                                        }>
                                            <span className={'btn-txt'} >Get started</span>
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-273.000000, -115.000000)" fill="#ffffff">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M250.723579,23.7204755 L251.444132,22.9984485 C251.840432,22.6013317 252.17198,22.2764214 252.180919,22.2764214 C252.189845,22.2764214 253.215608,23.2967371 254.460372,24.5438098 L256.723579,26.8111982 L258.986785,24.5438098 C260.23155,23.2967371 261.257299,22.2764214 261.266225,22.2764214 C261.275165,22.2764214 261.606725,22.6013317 262.003025,22.9984485 L262.723579,23.7204755 L259.72764,26.722027 C258.079878,28.3728882 256.728048,29.7235786 256.723579,29.7235786 C256.719109,29.7235786 255.367279,28.3728882 253.719517,26.722027 L250.723579,23.7204755 Z"
                                                                id="Fill-1-Copy-3" transform="translate(256.723579, 26.000000) rotate(-90.000000) translate(-256.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>}

                                        {this.props.inPage == 'broker-finder' &&  <a className={'btn btn-right-link active'} href="/loan-enquiry" onClick={
                                            (event) => {
                                                this.handleClick(event, this.props.inPage);
                                            }
                                        }>
                                            <span className={'btn-txt'} >Book appointment</span>
                                            {/* <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-273.000000, -115.000000)" fill="#ffffff">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M250.723579,23.7204755 L251.444132,22.9984485 C251.840432,22.6013317 252.17198,22.2764214 252.180919,22.2764214 C252.189845,22.2764214 253.215608,23.2967371 254.460372,24.5438098 L256.723579,26.8111982 L258.986785,24.5438098 C260.23155,23.2967371 261.257299,22.2764214 261.266225,22.2764214 C261.275165,22.2764214 261.606725,22.6013317 262.003025,22.9984485 L262.723579,23.7204755 L259.72764,26.722027 C258.079878,28.3728882 256.728048,29.7235786 256.723579,29.7235786 C256.719109,29.7235786 255.367279,28.3728882 253.719517,26.722027 L250.723579,23.7204755 Z"
                                                                id="Fill-1-Copy-3" transform="translate(256.723579, 26.000000) rotate(-90.000000) translate(-256.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg> */}
                                        </a>}
                                    </div>
                                </div>
                            </div>}
                            {this.props.layout == 'full' && <div className={'sticky-buttons-main'}>
                                <div className={'row'}>
                                    <div className={'col-sm-6 sticky-buttons btn-left'}>
                                        <a className={'btn btn-left-link'} href="javascript://" onClick={
                                            (event) => {
                                                this.handleBackClick(event, this.props.inPage);
                                            }
                                        }>
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-40.000000, -115.000000)" fill="#333333">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M17.7235786,23.7204755 L18.444132,22.9984485 C18.8404319,22.6013317 19.1719795,22.2764214 19.1809189,22.2764214 C19.1898453,22.2764214 20.2156076,23.2967371 21.4603719,24.5438098 L23.7235786,26.8111982 L25.9867852,24.5438098 C27.2315495,23.2967371 28.2572988,22.2764214 28.2662253,22.2764214 C28.2751647,22.2764214 28.6067252,22.6013317 29.0030251,22.9984485 L29.7235786,23.7204755 L26.7276402,26.722027 C25.0798779,28.3728882 23.7280483,29.7235786 23.7235786,29.7235786 C23.7191089,29.7235786 22.3672792,28.3728882 20.719517,26.722027 L17.7235786,23.7204755 Z"
                                                                id="Fill-1-Copy-4" transform="translate(23.723579, 26.000000) rotate(-270.000000) translate(-23.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className={'btn-txt'} >Back</span>
                                        </a>
                                    </div>

                                    {this.props.inPage == 'choose-deal' && <div className={'col-sm-6 sticky-buttons btn-right'}>
                                        <a className={'btn btn-right-link active'} href="/loan-enquiry" onClick={
                                            (event) => {
                                                this.handleClick(event, this.props.inPage, 'mortgage-broker-finder');
                                            }
                                        }>
                                            <span className={'btn-txt'} >Choose deal</span>
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-273.000000, -115.000000)" fill="#ffffff">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M250.723579,23.7204755 L251.444132,22.9984485 C251.840432,22.6013317 252.17198,22.2764214 252.180919,22.2764214 C252.189845,22.2764214 253.215608,23.2967371 254.460372,24.5438098 L256.723579,26.8111982 L258.986785,24.5438098 C260.23155,23.2967371 261.257299,22.2764214 261.266225,22.2764214 C261.275165,22.2764214 261.606725,22.6013317 262.003025,22.9984485 L262.723579,23.7204755 L259.72764,26.722027 C258.079878,28.3728882 256.728048,29.7235786 256.723579,29.7235786 C256.719109,29.7235786 255.367279,28.3728882 253.719517,26.722027 L250.723579,23.7204755 Z"
                                                                id="Fill-1-Copy-3" transform="translate(256.723579, 26.000000) rotate(-90.000000) translate(-256.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>}

                                    {this.props.inPage == 'book-appointment' && <div className={'col-sm-6 sticky-buttons btn-right'}>
                                        <a className={'btn btn-right-link active'} href="/loan-enquiry" onClick={
                                            (event) => {
                                                this.handleClick(event, this.props.inPage, 'mortgage-broker-finder');
                                            }
                                        }>
                                            <span className={'btn-txt'} >Book appointment</span>
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-273.000000, -115.000000)" fill="#ffffff">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M250.723579,23.7204755 L251.444132,22.9984485 C251.840432,22.6013317 252.17198,22.2764214 252.180919,22.2764214 C252.189845,22.2764214 253.215608,23.2967371 254.460372,24.5438098 L256.723579,26.8111982 L258.986785,24.5438098 C260.23155,23.2967371 261.257299,22.2764214 261.266225,22.2764214 C261.275165,22.2764214 261.606725,22.6013317 262.003025,22.9984485 L262.723579,23.7204755 L259.72764,26.722027 C258.079878,28.3728882 256.728048,29.7235786 256.723579,29.7235786 C256.719109,29.7235786 255.367279,28.3728882 253.719517,26.722027 L250.723579,23.7204755 Z"
                                                                id="Fill-1-Copy-3" transform="translate(256.723579, 26.000000) rotate(-90.000000) translate(-256.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}