import React, { Component } from "react";
import cx from "classnames";
import Styles from '../assets/styles/pagination.module.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faChevronRight, faChevronLeft)




class Pagination extends Component {

  onChange(e) {
    this.props.onChangePage(e.target.text);
  }

  onNextPageDesktop(e) {

    if (document.getElementById("onNextPageDesktop").getAttribute('nextpagenumber')) {
      this.props.onChangePage(document.getElementById("onNextPageDesktop").getAttribute('nextpagenumber'));
    }

  }

  onBackPageDesktop(e) {

    if (document.getElementById("onBackPageDesktop").getAttribute('backpagenumber')) {
      this.props.onChangePage(document.getElementById("onBackPageDesktop").getAttribute('backpagenumber'));
    }
  }

  onNextPageMobile(e) {
    if (e.target.getAttribute('nextpagenumber')) {
      this.props.onChangePage(e.target.getAttribute('nextpagenumber'));
    }

  }

  onBackPageMobile(e) {
    if (e.target.getAttribute('backpagenumber')) {
      this.props.onChangePage(e.target.getAttribute('backpagenumber'));
    }
  }



  render() {
    let { pageCount, currentPage } = this.props;
    let nextPage = parseInt(currentPage) + 1
    let backPage = parseInt(currentPage) - 1

    let list = [];
    let listDots = [];
    let lastPagCount = [];
    let start = 0;
    let visiblePageCount = (pageCount - currentPage)
    if (visiblePageCount < 6) {
      start = (pageCount - 5) >= 0 ? pageCount - 5 : currentPage
      start = start == 0 ? 1 : start
    } else {
      start = currentPage
    }

    for (let i = start; i <= pageCount; i++) {
      list.push(i);
      if (i == pageCount) {
        lastPagCount.push(i);
      }
    }

    if (visiblePageCount > 6) {
      list = list.slice(0, 6)
      listDots.push(0);
    }
    else {
      lastPagCount = [];
    }


    return (

      <div className={cx(Styles.dealspaginationMain, 'text-center')} >
        <div className={Styles.dealspagination}>
          <ul className={Styles.pagination}>
            {/* back Arrow starts*/}
            {
              (currentPage == 1) ? '' : <li className={Styles.paginationArrows}>
                <a id="onBackPageDesktop" onClick={this.onBackPageDesktop.bind(this)} backpagenumber={backPage}>
                  <i className={cx(Styles.fa, Styles.faChevronRight)} aria-hidden="true">
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </i>
                </a>
              </li>
            }
            {/* back Arrow ends*/}

            {
              list.map((i) =>
                <li key={i}>
                  <a className={i == currentPage ? Styles.active : Styles.pageNumber}
                    onClick={this.onChange.bind(this)}>{i}</a>
                </li>
              )
            }
            {
              listDots.map((i) =>
                <li key={i} className={Styles.paginationDotted}>
                  ...
              </li>
              )
            }
            {
              lastPagCount.map((i) =>
                <li key={i}>
                  <a className={i == currentPage ? Styles.active : Styles.pageNumber}
                    onClick={this.onChange.bind(this)}>{i}</a>
                </li>
              )
            }
            {/* Next Arrow starts*/}
            {
              (currentPage >= (pageCount - currentPage)) ? '' : <li className={Styles.paginationArrows}>
                <a id="onNextPageDesktop" onClick={this.onNextPageDesktop.bind(this)} nextpagenumber={nextPage}>
                  <i className={cx(Styles.fa, Styles.faChevronRight)} aria-hidden="true">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </a>
              </li>
            }
            {/* Next Arrow ends*/}

          </ul>
        </div>
        <div className={cx(Styles.deallistingbtn, 'text-center')}>

          {/* Next button starts*/}
          {
            (currentPage == pageCount || pageCount <= 1) ? '' : <a nextpagenumber={nextPage} onClick={this.onNextPageMobile.bind(this)} className={Styles.btnlink} href="javascript://">
              Next
              </a>
          }
          {/* Next button ends*/}

          {/* page2 link start*/}
          {/* <a className={Styles.btnlink} href="javascript://">
            <span className={cx(Styles.btnArrowLeft, Styles.btnArrow)}>
            </span>
            <span className={Styles.btnTxt}>Page 2</span>
            <span className={cx(Styles.btnArrowRight, Styles.btnArrow)}>
            </span>
          </a> */}
          {/* page2 link end*/}
          {/* page3 link start*/}
          {/* <a className={Styles.btnlink} href="javascript://">
            <span className={cx(Styles.btnArrowLeft, Styles.btnArrow)}>
            </span>
            <span className={Styles.btnTxt}>Page 3</span>
            <span className={cx(Styles.btnArrowRight, Styles.btnArrow)}>
            </span>
          </a> */}
          {/* page3 link ends*/}

          {/* Back button starts*/}
          {
            (currentPage == 1) ? '' : <a backpagenumber={backPage} onClick={this.onBackPageMobile.bind(this)} className={Styles.btnlink} href="javascript://">
              Back
                </a>
          }
          {/* Back button ends*/}




        </div>
      </div>
    )
  }
};

export default Pagination;