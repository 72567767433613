
export const customSearch = (deal_type_id, propertyTyp) => {
    let propertyTypArr = propertyTyp ? propertyTyp.split(",") : [];
    let result = -1
    for (var i = 0, len = propertyTypArr.length; i < len; i++) {
        let result = deal_type_id.search(propertyTypArr[i]);
        if (result >=0) {
            return result
        }
    }
    return result
}

export const customSearchMortgageLoanAmount = (minimum_borrow_amount, mortgageLoanAmount) => {
    var isValid = 0;
    if (parseInt(minimum_borrow_amount) > parseInt(mortgageLoanAmount) && minimum_borrow_amount!='') {                                   
        isValid = -1;
    }
    return isValid;
}

export const customSearchLvr = (loan_to_value_ratio, lvr) => {
    if (loan_to_value_ratio >= lvr) {
      return 0
    } else {
      return -1
    }
}

export const calculateMontlyRepayments = (intRate, termRange, mortgageAmount) => {
    var getDefaultInterestRate = (intRate / 100);
    var interestRatePerFrequency = getDefaultInterestRate / 12;
    var A = mortgageAmount;
    var N = 12 * termRange;
    var newRepaymentsAmount = (interestRatePerFrequency * A) / (1 - Math.pow((1 + interestRatePerFrequency), -N));

    if (newRepaymentsAmount == 'Infinity' || newRepaymentsAmount < 1 || isNaN(newRepaymentsAmount)) {
        return 0;
    }
    return Math.round(newRepaymentsAmount).toString();
}