import React, { Component } from "react";
import Layout from "../../components/layout";
import DealList from "../../components/deals/dealList";
import GetStarted from '../../components/deals/getStarted';
import Breadcrumbs from "../../components/breadcrumbs";
import PageHeading from "../../components/pageHeading";
import HowItWorksSteps from '../../components/howItWorksSteps';
import CustomHelmet from '../../components/customHelmet';
import { graphql } from 'gatsby'; 
import FooterBottomContact from "../../components/footerBottomContact";
import allKenticoCloudItemPageCompareloans from "../../../data/kentico/allKenticoCloudItemPageCompareloans";

import Cookies from 'universal-cookie';


// const AddClassInBody = () => {
//   if (typeof document != 'undefined') {
//     if (document.getElementsByTagName('body') 
//       && typeof document.getElementsByTagName('body') == 'object') {
//       let body = document.getElementsByTagName('body')[0];
//       body.className='';
//       body.classList.add('laondealPage')
//     }
//   }
// }


class Deals extends Component {
  constructor(props) {
    super(props);
     //this.compareLoanContent = props.data.allKenticoCloudItemPageCompareloans;
     this.compareLoanContent = allKenticoCloudItemPageCompareloans;
     
     const headingAndDescription = this.compareLoanContent.edges[0].node.elements.label_headinganddescription[0].elements;
     const howitwrkstp = this.compareLoanContent.edges[0].node.elements.label_howitworks_nodes[0].elements;
      const Getstrted = this.compareLoanContent.edges[0].node.elements.get_started_section[0].elements;
      
    this.state = {
      breadcrumbs:
      [
        {
          name:'Compare Loans',
          link: '/hashching-home-loan-deals'
        }
      ],
      page_headings:
      {
        title:headingAndDescription.page_title.value,
        description:headingAndDescription.page_description.value
      },
      steps_heading:
      {
        label:howitwrkstp.label_heading.value
      },
       get_started:
       {
         button:Getstrted.label_button[0].elements.label.value,
         link:Getstrted.label_button[0].elements.href.value,
         labelText1:Getstrted.label_text1.value,
         labelText2:Getstrted.label_text2prefix.value,
         labelText3:Getstrted.label_textpostfix2.value
       },
      list:
      [
        {
          className:'active',
          labelText:howitwrkstp.label_step1.value
        },
        {
          className:'',
          labelText:howitwrkstp.label_step2.value
        },
        {
          className:'',
          labelText:howitwrkstp.label_step3.value
        },
        {
          className:'',
          labelText:howitwrkstp.label_step4.value
        },
        {
          className:'',
          labelText:howitwrkstp.label_step5.value
        },
        {
          className:'',
          labelText:howitwrkstp.label_step6.value
        }
      ]
    };
  }

  componentDidMount() {
    //window.location.replace("/");
  }

  render() {

    const cookies = new Cookies();
    cookies.remove('broker', { 'path': '/' });
    cookies.remove('deal', { 'path': '/' });
     
    const node = allKenticoCloudItemPageCompareloans.edges[0],
        seo_title = node.node.elements.section___seo_content_508c18c__seo_title.value,
        seo_description = node.node.elements.section___seo_content_508c18c__seo_description.value,
        seo_keywords = node.node.elements.section___seo_content_508c18c__seo_keywords.value,
        seo_canonicalurl = node.node.elements.section___seo_content_508c18c__seo_canonicalurl.value;

     return ( 
      <Layout>     

       <CustomHelmet>
          <title>{seo_title}</title>
          <meta name="description" content={seo_description}/>
          <meta name="keywords" content={seo_keywords}/>          
          <meta property="og:description" content={seo_description}/>
          <meta property="og:title" content={seo_title} />
          <meta property="og:url" content={seo_canonicalurl} />          
          <meta name="twitter:description" content={seo_description} />
          <meta name="twitter:title" content={seo_title} />
         <link rel="canonical" href={seo_canonicalurl} />
         
      </CustomHelmet>

        <Breadcrumbs breadcrumbs={this.state.breadcrumbs}/>
        <PageHeading headings={this.state.page_headings}/>
        <HowItWorksSteps steps={this.state.list} heading={this.state.steps_heading}/>
        <DealList />
        {/*<FooterBottomContact content={'asd'} layout={'middle'} inPage={'deal-list'}></FooterBottomContact> 
        
          <GetStarted get_started_data={this.state.get_started} /> */}
         {/* {AddClassInBody()} */}
       </Layout>
      
    )
  }
}

export default Deals;
// export const query = graphql`
// {
//   allKenticoCloudItemPageCompareloans {
//     edges {
//       node {
//         elements {
//           label_headinganddescription {
//             elements {
//               page_title {
//                 value
//               }
//               page_description {
//                 value
//               }
//             }
//           }
//           label_howitworks_nodes {
//             elements {
//               label_heading {
//                 value
//               }
//               label_step1 {
//                 value
//               }
//               label_step2 {
//                 value
//               }
//               label_step3 {
//                 value
//               }
//               label_step4 {
//                 value
//               }
//               label_step5 {
//                 value
//               }
//               label_step6 {
//                 value
//               }
//             }
//           }
//            get_started_section {
//             elements {
//               label_text1 {
//                 value
//               }
//               label_text2prefix {
//                 value
//               }
//               label_textpostfix2 {
//                 value
//               }
//               label_button {
//                 elements {
//                   label {
//                     value
//                   }
//                   href {
//                     value
//                   }
//                 }
//                 }
//               }
//             }
        
         
          
//           label_assumption {
//             value
//           }
//           section___seo_content_508c18c__seo_title {
//             value
//           }
//           section___seo_content_508c18c__seo_keywords {
//             value
//           }
//           section___seo_content_508c18c__seo_description {
//             value
//           }
//           section___seo_content_508c18c__seo_canonicalurl {
//             value
//           }
//         }
//       }
//     }
//   }
// }
// `;